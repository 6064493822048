import styled from 'styled-components'
import { Text } from '../../../elements/typography'
import { media } from '../../../style/media'

export const LandingPageParagraph = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  line-height: ${(props) => props.theme.fontSizes.larger * 1.5}px;
  text-align: ${(props) => props.textAlign || 'center'};
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'small']}px;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
    line-height: ${(props) => props.theme.fontSizes.xlarge * 1.5}px;
  `};

  ${media.md`
    text-align: ${(props) => props.textAlign || 'left'};
  `};
`
