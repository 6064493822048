import { Pressable, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { Icon } from '../../../elements/images/Icon/index.js'
import Image from '../../../elements/images/Image/Image'
import { ContentLimitedWidth, useLayout } from '../../../elements/layout'
import { FullScreenBgSection, PaddedSection } from '../../../elements/sections'
import { Text } from '../../../elements/typography'
import { commonStyles } from '../../../style/commonStyles'
import { media, useMediaIs, useMediaSelect } from '../../../style/media'
import {
  CTAButton,
  HeroHeading,
  LandingPageParagraph,
  LandingSignInButton,
} from '../../landing-pages'
import { LandingPageSmallText } from '../../landing-pages/LandingPageSmallText/LandingPageSmallText'

const HeaderRow = styled(View)`
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const Body = styled(View)`
  margin: auto;
  width: 100%;

  ${media.lg`
    margin: auto 0;
    max-width: 760px;
  `};
`

export const LearnMore = styled(View)`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.xxxlarge}px;
  align-items: center;

  ${media.sm`
    margin-top: ${(props) => props.theme.spacing.xxxlarge * 2}px;
  `};

  ${media.lg`
    margin-top: 0;
  `};
`

export const LearnMoreText = styled(Text)`
  margin-bottom: ${(props) => -props.theme.spacing.small}px;
  text-align: center;
`

const responsive = {
  lg: {},
  md: {
    maxHeight: 1000,
  },
}

export const HeroSection = ({
  heading,
  description,
  button,
  bgImgIdLandscape,
  bgImgIdPortrait,
  region,
  onLayout,
  onScrollToIndex,
  sectionIndex,
}) => {
  const theme = useTheme()
  const isScreenLg = !useMediaIs('lg')
  const isScreenAboveMd = useMediaIs('md')
  const logoHeight = useMediaSelect({
    xs: 55,
    sm: 66,
    lg: 99,
  })

  const { onLayout: sectionLayout, height } = useLayout()
  const isScreenShort = height <= 660

  return (
    <FullScreenBgSection
      bgImgIdLandscape={bgImgIdLandscape}
      bgImgIdPortrait={bgImgIdPortrait}
      gradientDirection={isScreenAboveMd ? 1 : null}
      headerOffset={false}
      responsive={responsive}
      contentStyle={commonStyles.flex1}
      onLayout={(e) => {
        sectionLayout(e)
        onLayout(e)
      }}
    >
      <PaddedSection style={commonStyles.flex1}>
        <ContentLimitedWidth style={commonStyles.flex1}>
          <HeaderRow>
            <Image
              height={logoHeight}
              width={(logoHeight * 192) / 131}
              publicId="web/flix-logo-white-192"
            />
            <LandingSignInButton style={{ marginLeft: 'auto' }} />
          </HeaderRow>
          <Body>
            <HeroHeading
              textAlign={isScreenLg ? 'center' : 'left'}
              mt={isScreenShort ? 'xxlarge' : null}
            >
              {heading}
            </HeroHeading>
            <LandingPageParagraph
              // Custom style font size required by the designer.
              style={{ fontSize: isScreenLg ? theme.fontSizes.larger : theme.fontSizes.medium }}
              mb={isScreenShort ? 'small' : isScreenLg ? 'xxlarge' : 'large'}
              textAlign={isScreenLg ? 'center' : 'left'}
            >
              {description}
            </LandingPageParagraph>
            <CTAButton
              alignSelf={isScreenLg ? 'center' : 'flex-start'}
              minWidth={isScreenLg ? 280 : undefined}
              config={button}
            />
            <LandingPageSmallText textAlign={isScreenLg ? 'center' : 'left'}>
              30 Day Free Trial - {region === 'GB' ? '£5.99' : '$6.99'}/month
            </LandingPageSmallText>
            <LandingPageSmallText textAlign={isScreenLg ? 'center' : 'left'}>
              No commitment. Cancel Anytime.
            </LandingPageSmallText>
          </Body>

          <LearnMore>
            <LearnMoreText>Learn More</LearnMoreText>
            <Pressable onPress={() => onScrollToIndex(sectionIndex + 1)}>
              <Icon name="chevron-down" scale={1.5} />
            </Pressable>
          </LearnMore>
        </ContentLimitedWidth>
      </PaddedSection>
    </FullScreenBgSection>
  )
}
