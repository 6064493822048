import { View } from 'react-native'
import styled from 'styled-components'
import { ImagePlaceholder } from '../../../elements/images/ImagePlaceholder/ImagePlaceholder.js'

const Container = styled(View)`
  align-items: ${(props) => props.alignItems || 'center'};
  margin-top: ${(props) => props.theme.spacing[props.mt] || 0}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb] || 0}px;
`

export const BannerAdPlaceholder = ({ mb, mt, width, height, bgEnabled }) => (
  <Container mb={mb} mt={mt}>
    <ImagePlaceholder width={width} height={height} disabled={!bgEnabled} />
  </Container>
)
