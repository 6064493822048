const noAds = { error: 'error', message: 'no ads' }

export const fetchBannerAdDecision = async (placementConfig) => {
  const { url, method, data } = placementConfig

  if (!method || !url) {
    return noAds
  }

  const queryParams = method === 'GET' ? `?${new URLSearchParams(data)}` : ''

  try {
    const response = await fetch(`${url}${queryParams}`, {
      method,
      mode: 'cors',
      body: method === 'POST' ? JSON.stringify(data) : undefined,
    })

    return response.ok ? response.json() : noAds
  } catch (error) {
    return noAds
  }
}
