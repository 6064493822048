import { Platform, StyleSheet, View } from 'react-native'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { defaultTransforms } from '../../../services/cloudinary'
import Image from '../Image/Image'

const positions = {
  'top-left': { top: 0, left: 0 },
  top: { top: 0, left: 0, right: 0 },
  'top-right': { top: 0, right: 0 },
  right: { top: 0, bottom: 0, right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  bottom: { bottom: 0, left: 0, right: 0 },
  'bottom-right': { bottom: 0, right: 0 },
  left: { top: 0, bottom: 0, left: 0 },
  center: { top: 0, bottom: 0, left: 0, right: 0 },
}

export const BackgroundImage = ({
  bgImgId,
  bgImgSource,
  gradient,
  width,
  height: explicitHeight,
  aspectRatio,
  flex,
  borderRadius,
  backgroundColor,
  resizeMode,
  backgroundPosition = 'center',
  transforms,
  style,
  children,
  blur,
  ...props
}) => {
  const theme = useTheme()
  const height = aspectRatio ? Math.ceil(width / aspectRatio) : explicitHeight
  return (
    <View
      accessibilityIgnoresInvertColors={true}
      style={[
        {
          width,
          height,
          borderRadius,
          flex,
          backgroundColor: backgroundColor || theme.colors.background,
        },
        style,
      ]}
    >
      {(bgImgId || bgImgSource) && (
        <Image
          {...props}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
          publicId={bgImgId}
          source={bgImgSource}
          resizeMode={resizeMode}
          transforms={
            !gradient
              ? transforms
              : [
                  {
                    ...defaultTransforms,
                    width: Math.round(width),
                    height: !aspectRatio ? height : undefined,
                    aspectRatio,
                    ...transforms,
                  },
                  {
                    background: theme.colors.background,
                    effect: `gradient_fade:${gradient.strength || '20'}`,
                    x: gradient.x ? gradient.x : null,
                    y: gradient.y ? gradient.y : null,
                  },
                  // Second gradient transformation if needed
                  gradient?.subGradTransforms
                    ? {
                        background: theme.colors.background,
                        effect: `gradient_fade:${
                          gradient.subGradTransforms.strength || gradient.strength || '20'
                        }`,
                        x: gradient.subGradTransforms.x ? gradient.subGradTransforms.x : null,
                        y: gradient.subGradTransforms.y ? gradient.subGradTransforms.y : null,
                      }
                    : {},
                ]
          }
          style={StyleSheet.compose(
            {
              position: 'absolute',
              ...positions[backgroundPosition],
              width,
              height,
              borderRadius,
              zIndex: -1,
            },
            blur &&
              Platform.OS === 'web' && {
                filter: `blur(${blur}rem)`,
              },
          )}
        />
      )}
      {children}
    </View>
  )
}

BackgroundImage.propTypes = {
  /** Background color of the panel if no image id is provided */
  backgroundColor: PropTypes.string,
  /** Cloudinary ID of the background image */
  bgImgId: PropTypes.string,
  /** Image width */
  width: PropTypes.number,
  /** Image height */
  height: PropTypes.number,
}

export default BackgroundImage
