import { Platform } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { H2, Html, Paragraph } from '@flix/common/elements/typography'
import { media, useMediaIs } from '@flix/common/style/media'

const isWeb = Platform.OS === 'web'

export const Heading = styled(H2)`
  margin-bottom: ${(props) => props.theme.spacing[props.margin || 'medium']}px;
  font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
  line-height: ${(props) => props.theme.fontSizes.xxlarge * 1.2}px;
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xxlarge * 1.2}px;
    line-height: ${(props) => props.theme.fontSizes.xxlarge * 1.4}px;
  `};
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.xxxlarge}px;
    line-height: ${(props) => props.theme.fontSizes.xxxlarge * 1.15}px;
  `};
`

export const Description = styled(Paragraph)`
  margin-bottom: ${(props) => props.theme.spacing[props.margin || 'larger']}px;
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  line-height: ${(props) => props.theme.fontSizes.medium * 1.3}px;
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
    line-height: ${(props) => props.theme.fontSizes.larger * 1.2}px;
  `};
`

// HTML content on web gets rendered as `span`, so this is required to apply certain styles to text
const webStyles = !isWeb
  ? {}
  : {
      marginTop: 0,
      marginBottom: 0,
      display: 'inline-block',
    }

export const StyledHtml = ({ textAlign = 'left', ...props }) => {
  const theme = useTheme()
  const sm = useMediaIs('sm')

  return (
    <Html
      tagsStyles={{
        h1: {
          ...webStyles,
          textAlign,
          fontSize: theme.fontSizes[sm ? 'xxxlarge' : 'xxlarge'],
          lineHeight: theme.fontSizes[sm ? 'xxxlarge' : 'xxlarge'],
          fontWeight: theme.fontWeights.bold.toString(),
        },
        h2: {
          ...webStyles,
          textAlign,
          color: theme.colors.grayLight,
        },
        p: {
          ...webStyles,
          textAlign,
          fontSize: theme.fontSizes.md,
        },
      }}
      {...props}
    />
  )
}
