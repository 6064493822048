import { useState } from 'react'
import { ExpandingText } from './ExpandingText'

export const ExpandingTextList = ({ list }) => {
  const [expanded, setExpanded] = useState(null)

  const toggleExpand = (index) => setExpanded((expanded) => (expanded === index ? null : index))

  return list.map((item, index) => (
    <ExpandingText
      {...item}
      key={index}
      expanded={expanded === index}
      onPress={() => toggleExpand(index)}
    />
  ))
}
