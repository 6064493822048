import { useCallback, useEffect, useState } from 'react'
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated'

export const AnimatedView = Animated.View

export const useHeightOpacityAnimation = ({ shown, overrideHeight, enabled = true }) => {
  const [measuredHeight, setHeight] = useState(0)

  const onLayout = useCallback(
    (e) => {
      const { height } = e.nativeEvent.layout
      setHeight(Math.max(height, overrideHeight ? null : measuredHeight))
    },
    [measuredHeight],
  )

  const opacity = useSharedValue(0)
  const height = useDerivedValue(
    () => interpolate(opacity.value, [0, 1], [0, measuredHeight]),
    [measuredHeight],
  )

  const animatedStyles = useAnimatedStyle(() => ({
    opacity: withTiming(opacity.value),
    height: withTiming(height.value),
  }))

  useEffect(() => {
    opacity.value = !enabled || shown ? 1 : 0
  }, [enabled, shown]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    animatedStyles,
    onLayout,
  }
}
