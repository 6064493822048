import { View } from 'react-native'
import styled from 'styled-components'
import { media } from '../../../style/media'

export const PaddedSection = styled(View)`
  width: 100%;
  flex-grow: 1;
  padding-top: ${(props) => props.theme.spacing.xlarge}px;
  padding-bottom: ${(props) => props.theme.spacing.xlarge}px;
  justify-content: center;

  ${media.sm`
    padding-top: ${(props) => props.theme.spacing.xlarge}px;
    padding-bottom: ${(props) => props.theme.spacing.xlarge}px;
  `};

  ${media.md`
    padding-top: ${(props) => props.theme.spacing.xxlarge}px;
    padding-bottom: ${(props) => props.theme.spacing.xxlarge}px;
  `};
`
