import { useEffect, useState } from 'react'
import Image from '../../elements/images/Image/Image.js'

export const useImageAspectRatio = (image) => {
  const [aspectRatio, setAspectRatio] = useState(null)

  useEffect(() => {
    if (image) {
      Image.getSize(image, (width, height) => setAspectRatio(width / height))
    } else {
      setAspectRatio(null)
    }
  }, [image])

  return aspectRatio
}
