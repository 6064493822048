import { Pressable, View } from 'react-native'
import styled from 'styled-components'
import { HeightOpacityAnimatedView } from '../../../animation/heightOpacityAnimation/index.js'
import { media } from '../../../style/media'
import { Icon } from '../../images/Icon'
import { Text } from '../../typography'

const Container = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'small']}px;
`

const PressableHeading = styled(Pressable)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grayDark};
  padding-top: ${(props) => props.theme.spacing.xsmall}px;
  padding-bottom: ${(props) => props.theme.spacing.xsmall}px;
  padding-left: ${(props) => props.theme.spacing.large}px;
  padding-right: ${(props) => props.theme.spacing.medium}px;
`

const HeadWrapper = styled(View)`
  flex: 1 1 auto;
`

const Expansion = styled(View)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grayDark};
  margin-top: 1px;
  padding: ${(props) => props.theme.spacing.larger}px ${(props) => props.theme.spacing.large}px;
`

export const Heading = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.large}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.3}px;
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-right: auto;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
    line-height: ${(props) => props.theme.fontSizes.larger * 1.3}px;
  `};
`

export const Description = styled(Text)`
  flex: 1 1 auto;
  font-size: ${(props) => props.theme.fontSizes.large}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.3}px;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
    line-height: ${(props) => props.theme.fontSizes.larger * 1.3}px;
  `};
`

export const ExpandingText = ({ heading, description, expanded, onPress }) => (
  <Container>
    <PressableHeading onPress={onPress}>
      <HeadWrapper>
        <Heading>{heading}</Heading>
      </HeadWrapper>
      <Icon name={expanded ? 'chevron-up' : 'chevron-down'} scale={1.8} />
    </PressableHeading>
    <HeightOpacityAnimatedView shown={expanded}>
      <Expansion>
        <Description>{description}</Description>
      </Expansion>
    </HeightOpacityAnimatedView>
  </Container>
)
