import { View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { useDimensions } from '../../../hooks'
import { breakPoints } from '../../../style/breakPoints'
import { media, useMediaSelect } from '../../../style/media'

export const ContentLimitedWidth = styled(View)`
  width: 100%;
  max-width: ${breakPoints.lg}px;
  padding-left: ${(props) => props.theme.spacing.medium}px;
  padding-right: ${(props) => props.theme.spacing.medium}px;
  margin-left: auto;
  margin-right: auto;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};

  ${media.sm`
    padding-left: ${(props) => props.theme.spacing.xlarge}px;
    padding-right: ${(props) => props.theme.spacing.xlarge}px;
  `};

  ${media.lg`
    padding-left: ${(props) => props.theme.spacing.small}px;
    padding-right: ${(props) => props.theme.spacing.small}px;
  `};
`

export const useContentLimitedWidth = () => {
  const theme = useTheme()
  const { width } = useDimensions()
  const padding = useMediaSelect({
    xs: theme.spacing.medium,
    sm: theme.spacing.xlarge,
    lg: theme.spacing.small,
  })
  return Math.min(width, breakPoints.lg) - padding * 2
}
