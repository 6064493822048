import { View } from 'react-native'
import styled from 'styled-components'
import { media } from '@flix/common/style/media'
import { ConfigurableButton } from './ConfigurableButton'
import { Content } from './Content'
import { Description, Heading } from './typography'

const InfoLeft = styled(View)`
  margin-left: ${(props) => props.theme.spacing.medium}px;
  padding-right: ${(props) => props.theme.spacing.medium}px;
  flex-shrink: 0;
  width: 75%;
  ${media.sm`
    width: 65%;
  `};
  ${media.md`
    margin-left: ${(props) => props.theme.spacing.xlarge}px;
    padding-right: ${(props) => props.theme.spacing.xlarge}px;
    width: 48%;
  `};
  ${media.lg`
    width: 570px;
  `};
`

const ContentRight = styled(View)`
  margin-left: auto;
  margin-right: ${(props) => props.theme.spacing.larger}px;
  max-width: 710px;
  flex: 1 1 570px;
`
/**
 * @deprecated Prefer `ContentInfoLeftHtml` to make use of HTML headings/description
 */
export const ContentInfoLeft = ({ heading, headMargin, description, button, style, children }) => (
  <Content style={style}>
    <InfoLeft>
      <Heading margin={headMargin}>{heading}</Heading>
      <Description>{description}</Description>
      {button && <ConfigurableButton config={button} variant="primary" alignSelf="flex-start" />}
    </InfoLeft>
    <ContentRight>{children}</ContentRight>
  </Content>
)
