import { Linking, Platform, Pressable, View } from 'react-native'
import styled from 'styled-components'
import Image from '../../elements/images/Image/Image'
import { InView } from '../../elements/utils/inViewObserver/InView'
import { handleInViewChange } from './helpers/handleInViewChange'

const Container = styled(View)`
  align-items: ${(props) => props.alignItems || 'center'};
  margin-top: ${(props) => props.theme.spacing[props.mt] || 0}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb] || 0}px;
`

const isTV = Platform.isTV

export const AdInView = ({ data, threshold = 0.5, disabled = false, children }) => (
  <InView onChange={handleInViewChange(data, disabled)} threshold={threshold} triggerOnce>
    {children}
  </InView>
)

export const AdPlacement = ({ data, width, height, ...props }) => {
  let placement = (
    <AdInView data={data}>
      <Image source={{ uri: data?.images?.[0]?.url }} width={width} height={height} />
    </AdInView>
  )

  if (data?.clickUrl && !isTV) {
    placement = <Pressable onPress={() => Linking.openURL(data.clickUrl)}>{placement}</Pressable>
  }

  return <Container {...props}>{placement}</Container>
}
