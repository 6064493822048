import { View } from 'react-native'
import styled from 'styled-components'
import BackgroundImage from '../../../elements/images/BackgroundImage/BackgroundImage'
import { useDimensions } from '../../../elements/layout'
import { media, useMediaIs, useMediaSelect } from '../../../style/media'
import { LandingPageParagraph, VeryBoldHeading } from '../../landing-pages'

const ContentFullWidth = styled(View)`
  padding-bottom: ${(props) => (props.isMobile ? props.theme.spacing.large : 0)}px;
`

const Row = styled(View)`
  flex-direction: column-reverse;
  justify-content: center;

  ${media.md`
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  `};
`

const Col = styled(View)`
  flex: 1 1 50%;
  justify-content: center;
`

const ContentDescription = styled(View)`
  justify-content: center;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing.large}px;
  height: 100%;
  ${media.lg`
    align-items: start;
    padding: 0;
    margin: auto;
    width: 1200px;
  `};
`

const MaxWidthDescription = styled(View)`
  max-width: 600px;
`

const defaultGradient = { y: 0.7, strength: 3, subGradTransforms: { y: -0.7 } }
const defaultTransforms = { gravity: 'north' }

export const DescriptionBgImageOneColSection = ({
  heading,
  description,
  color,
  image,
  imageMobile,
  textPosition,
}) => {
  const isMobile = !useMediaIs('md')
  const isSm = !useMediaIs('sm')
  const { width } = useDimensions()

  const img = isSm && imageMobile ? imageMobile : image

  return (
    <ContentFullWidth isMobile={isMobile}>
      <Row justifyContent="center">
        <Col>
          {img && (
            <BackgroundImage
              bgImgId={img.publicId}
              width={width}
              height={Math.round(width / img.aspectRatio)}
              gradient={defaultGradient}
              transforms={defaultTransforms}
            >
              {!isMobile && (
                <ContentDescription>
                  <MaxWidthDescription>
                    {!!heading && (
                      <VeryBoldHeading color={color} fontSize="xxxlarge" textAlign="left">
                        {heading}
                      </VeryBoldHeading>
                    )}
                    {!!description && (
                      <LandingPageParagraph color={color}>{description}</LandingPageParagraph>
                    )}
                  </MaxWidthDescription>
                </ContentDescription>
              )}
            </BackgroundImage>
          )}
        </Col>
        {isMobile && (
          <Col>
            <ContentDescription alignText={textPosition}>
              {!!heading && (
                <VeryBoldHeading textAlign={isMobile ? 'center' : 'left'} color={color}>
                  {heading}
                </VeryBoldHeading>
              )}
              {!!description && (
                <LandingPageParagraph color={color}>{description}</LandingPageParagraph>
              )}
            </ContentDescription>
          </Col>
        )}
      </Row>
    </ContentFullWidth>
  )
}
