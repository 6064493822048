import PropTypes from 'prop-types'
import { LargeButton, useButtonHandlers } from '../../../elements/controls'
import { chooseButtonVariant } from '../../../elements/sections/SectionContent/ConfigurableButton'

export const CTAButton = ({ icon, link, config, ...props }) => {
  const { handlers, authStatus } = useButtonHandlers()
  const { action, text, textColor, variant } = chooseButtonVariant(authStatus, config)
  const onPress = () => handlers[action](props)

  return (
    <LargeButton
      {...props}
      icon={icon}
      title={text}
      variant={variant}
      titleColor={textColor}
      link={link}
      onPress={onPress}
    />
  )
}

CTAButton.propTypes = {
  /** Minimum button width, useful to ensure a set of buttons have the same size */
  minWidth: PropTypes.number,
  /** Button displays as a link rather than a button */
  link: PropTypes.bool,
  /** Optional SVG icon name that will be displayed on the button */
  icon: PropTypes.string,
  /** Press event handler */
  onPress: PropTypes.func,
  /** Set the flex box `align-self` style, use for positioned buttons rather than full width */
  alignSelf: PropTypes.string,
}
