import { Platform, View } from 'react-native'
import styled from 'styled-components'
import { AnimatedView, useHeightOpacityAnimation } from './useHeightOpacityAnimation'

const isWeb = Platform.OS === 'web'

const HeightMeasurement = styled(View)`
  position: absolute;
  left: 99999px;
`

export const HeightOpacityAnimatedView = ({ children, ...options }) => {
  const { animatedStyles, onLayout } = useHeightOpacityAnimation(options)
  return (
    <>
      <AnimatedView style={[{ overflow: 'hidden' }, animatedStyles]}>
        <View onLayout={isWeb ? onLayout : null}>{children}</View>
      </AnimatedView>
      {!isWeb && (
        <HeightMeasurement>
          <View onLayout={onLayout}>{children}</View>
        </HeightMeasurement>
      )}
    </>
  )
}
