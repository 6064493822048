import { View } from 'react-native'
import styled from 'styled-components'
import { useLayout } from '../../../hooks'
import Image from '../Image/Image'

const AlignedImage = styled(Image)`
  margin-left: ${(props) => (props.align === 'left' ? 0 : 'auto')};
  margin-right: ${(props) => (props.align === 'right' ? 0 : 'auto')};
`

export const ResponsiveImage = ({
  aspectRatio,
  maxHeight,
  transforms,
  style,
  imageStyle,
  align,
  ...props
}) => {
  const { width: measuredWidth, onLayout } = useLayout()
  const height = Math.min(maxHeight, measuredWidth / aspectRatio)
  const width = height * aspectRatio

  const imgSizeTransform = {
    ...transforms,
    height: maxHeight,
    width: Math.round(maxHeight * aspectRatio),
  }
  return (
    <View onLayout={onLayout} style={style}>
      {Boolean(measuredWidth) && (
        <AlignedImage
          {...props}
          width={width}
          height={width / aspectRatio}
          transforms={imgSizeTransform}
          style={imageStyle}
          align={align}
        />
      )}
    </View>
  )
}
