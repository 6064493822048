import styled from 'styled-components'
import { Text } from '../../../elements/typography'
import { media } from '../../../style/media'

export const HeroHeading = styled(Text).attrs({
  accessibilityRole: 'header',
  'aria-level': 1,
})`
  font-family: ${(props) => props.theme.fontFamilies.marketingHeadlineBold};
  font-weight: 800;
  text-align: ${(props) => props.textAlign || 'center'};
  font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'small']}px;
  ${(props) => props.mt && `margin-top:${props.theme.spacing[props.mt]}px`};

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xxxlarge}px;
  `}

  ${media.lg`
    text-align: ${(props) => props.textAlign || 'left'};
  `}
`
