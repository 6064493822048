import { useCallback, useMemo } from 'react'
import { Linking } from 'react-native'
import HTML from 'react-native-render-html'
import { useTheme } from 'styled-components'
import { merge } from 'lodash'
import PropTypes from 'prop-types'
import { useMediaIs } from '../../../style/media'
import { useButtonHandlers } from '../../controls/index.js'
import { useDimensions } from '../../layout'
import { B, Em, I, Strong, U } from '../BoldItalics/BoldItalics'
import { H1 } from '../H1/H1'
import { H2 } from '../H2/H2'
import { H3 } from '../H3/H3'
import { Paragraph } from '../Paragraph/Paragraph'
import { doubleBreaksToParagraphs } from './doubleBreaksToParagraphs'

const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi

export function makeHtmlSafe(text = '') {
  while (SCRIPT_REGEX.test(text)) {
    text = text.replace(SCRIPT_REGEX, '')
  }
  return { __html: text }
}

const renderer = (Component) => (htmlAttribs, children, styles, node) =>
  <Component key={node.key}>{children}</Component>

const renderers = {
  p: renderer(Paragraph),
  h1: renderer(H1),
  h2: renderer(H2),
  h3: renderer(H3),
  strong: renderer(Strong),
  b: renderer(B),
  em: renderer(Em),
  i: renderer(I),
  u: renderer(U),
}

const empty = {}

export const Html = ({
  as: Wrapper,
  content,
  keepLineBreaks,
  fontSize,
  style,
  tagsStyles = empty,
}) => {
  const source = useMemo(
    () => ({
      html: keepLineBreaks ? doubleBreaksToParagraphs(content) : content,
    }),
    [content, keepLineBreaks],
  )

  const { handlers } = useButtonHandlers()
  const onLinkPress = useCallback(
    (ev, href, { target }) => {
      if (target === '_self') {
        const viewDetailsMatch = href.match(/flixpremiere.com\/(film|actor)\/([\w-]+)$/)
        if (viewDetailsMatch) {
          return handlers.viewDetails({ type: viewDetailsMatch[1], slug: viewDetailsMatch[2] })
        }
      }
      Linking.openURL(href)
    },
    [handlers],
  )

  const theme = useTheme()
  const { width } = useDimensions()
  const sm = useMediaIs('sm')
  const baseFontStyle = {
    fontSize: theme.fontSizes[fontSize || 'medium'],
    color: theme.colors.text,
  }
  const finalTagsStyles = merge(
    {
      // Font sizes set in the components are not honoured, specify them here
      h1: {
        textAlign: 'center',
        fontSize: theme.fontSizes[sm ? 'xxlarge' : 'xlarge'],
        fontWeight: theme.fontWeights.bold.toString(),
      },
      h2: { fontSize: theme.fontSizes.xlarge, fontWeight: theme.fontWeights.bold.toString() },
      h3: { fontSize: theme.fontSizes.larger, fontWeight: theme.fontWeights.normal.toString() },
    },
    tagsStyles,
  )

  return (
    <HTML
      source={source}
      renderers={renderers}
      tagsStyles={finalTagsStyles}
      baseFontStyle={baseFontStyle}
      emSize={baseFontStyle.fontSize}
      contentWidth={width}
      customWrapper={Wrapper && ((html) => <Wrapper>{html}</Wrapper>)}
      containerStyle={style}
      onLinkPress={onLinkPress}
    />
  )
}

Html.propTypes = {
  /** An HTML string to render */
  content: PropTypes.string,
  /** Converts `\n` to `<br/>` and breaks text into paragraphs on double breaks */
  keepLineBreaks: PropTypes.bool,
  tagsStyles: PropTypes.shape({
    h1: PropTypes.object,
    h2: PropTypes.object,
    h3: PropTypes.object,
    p: PropTypes.object,
  }),
}
