import { View } from 'react-native'
import styled from 'styled-components'
import { media } from '@flix/common/style/media'
import { ConfigurableButton } from './ConfigurableButton'
import { Content as ContentBase } from './Content'
import { Description, Heading } from './typography'

const Content = styled(ContentBase)`
  flex-direction: column;
  align-items: stretch;
`

const ContentTop = styled(View)`
  width: 100%;
  flex: 1 1 auto;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing.small}px;
  margin-bottom: ${(props) => props.theme.spacing.larger}px;
  ${media.sm`
    padding: 0 ${(props) => props.theme.spacing.larger}px;
    margin-left: auto;
    margin-right: auto;
  `};
`

const InfoBottom = styled(View)`
  margin: 0 ${(props) => props.theme.spacing.medium}px;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  ${media.sm`
    margin: 0 auto;
    max-width: 540px;
  `};
  ${media.md`
    max-width: 610px;
  `};
`
/**
 * @deprecated Prefer `ContentInfoBottomHtml` to make use of HTML headings/description
 */
export const ContentInfoBottom = ({
  heading,
  headMargin,
  description,
  button,
  style,
  children,
}) => (
  <Content style={style}>
    {children && <ContentTop>{children}</ContentTop>}
    <InfoBottom>
      <Heading textAlign="center" margin={headMargin}>
        {heading}
      </Heading>
      <Description textAlign="center">{description}</Description>
      {button && <ConfigurableButton config={button} variant="primary" alignSelf="center" />}
    </InfoBottom>
  </Content>
)
