import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import styled from 'styled-components'
import { Icon } from '../../../elements/images/Icon'
import { ResponsiveImage } from '../../../elements/images/ResponsiveImage/ResponsiveImage'
import { media, useMediaIs } from '../../../style/media'
import { cursorPointer } from '../../../style/webStyles'
import { useButtonHandlers } from '../../controls'

const Content = styled(View)`
  width: 100%;
  padding: 0 ${(props) => props.theme.spacing.small}px;
  ${media.sm`
    width: 600px;
  `};
  ${media.lg`
    margin-right: ${(props) => props.theme.spacing.large}px;
    width: 100%;
  `};
  ${cursorPointer};
`

const PlayOverlay = styled(View)`
  justify-content: center;
  align-items: center;
`

export const VideoContent = ({ video, aspectRatio, maxHeight }) => {
  const sm = useMediaIs('sm')
  const { handlers } = useButtonHandlers()
  return (
    <TouchableWithoutFeedback onPress={() => handlers.playVideo({ video })}>
      <Content>
        <ResponsiveImage
          publicId={video.thumbnail}
          aspectRatio={aspectRatio}
          maxHeight={maxHeight}
        />
        <PlayOverlay style={StyleSheet.absoluteFill}>
          <Icon name="play-circle" scale={sm ? 2.5 : 1.5} color="#ffffff" />
        </PlayOverlay>
      </Content>
    </TouchableWithoutFeedback>
  )
}
