import { Platform } from 'react-native'
import { AboutHeroSection } from '../about/AboutHeroSection/AboutHeroSection'
import { AboutSection } from '../about/AboutSection/AboutSection'
import { AboutVideoSection } from '../about/AboutVideoSection/AboutVideoSection'
import { BannerAdSection } from './BannerAdSection/BannerAdSection'
import { DescriptionBgImageOneColSection } from './DescriptionBgImageOneColSection/DescriptionBgImageOneColSection'
import { DescriptionBgImageTwoColSection } from './DescriptionBgImageTwoColSection/DescriptionBgImageTwoColSection'
import { DescriptionImageOneColSection } from './DescriptionImageOneColSection/DescriptionImageOneColSection'
import { DescriptionImageTwoColSection } from './DescriptionImageTwoColSection/DescriptionImageTwoColSection'
import { ExpandingTextListSection } from './ExpandingTextListSection/ExpandingTextListSection'
import { HeroSection } from './HeroSection/HeroSection'

const isNative = Platform.OS !== 'web'

const components = {
  hero: HeroSection,
  descr_image_one_col: DescriptionImageOneColSection,
  descr_image_two_col: DescriptionImageTwoColSection,
  descr_bg_image_two_col: DescriptionBgImageTwoColSection,
  descr_bg_image_one_col: DescriptionBgImageOneColSection,
  banner_ad: BannerAdSection,
  expanding_text_list: ExpandingTextListSection,

  // Deprecated
  about_hero: AboutHeroSection,
  about_content: AboutSection,
  about_video: AboutVideoSection,
}

// todo The region should not be necessary once this is fully fed by the CMS
export const CMSSection = ({ section, index, region, onLayout, onScrollToIndex }) => {
  const Section = components[section.type]

  return !Section ? null : (
    <Section
      {...section}
      headerOffset={isNative || index !== 0}
      region={region}
      onLayout={onLayout}
      onScrollToIndex={onScrollToIndex}
      sectionIndex={index}
    />
  )
}
