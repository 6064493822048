import { PaddedSection } from '../../../elements/sections'
import { useMediaIs } from '../../../style/media'
import { AdPlacement } from '../../ads/AdPlacement.js'
import { NoAdInView } from '../../ads/NoAdInView'
import { BannerAdPlaceholder } from '../../ads/bannerAds/BannerAdPlaceholder'
import { useBannerAdDecision } from '../../ads/useBannerAdDecision.js'

export const BannerAdSection = ({ zone, placementXs, placementSm }) => {
  const sm = useMediaIs('sm')

  const placement = (sm ? placementSm : placementXs) ?? placementSm ?? placementXs

  const { bannerData, loading } = useBannerAdDecision(zone, placement)

  if (loading) {
    return (
      <PaddedSection>
        <BannerAdPlaceholder width={sm ? 728 : 300} height={sm ? 90 : 50} />
      </PaddedSection>
    )
  }

  if (bannerData?.message === 'no ads') {
    return <NoAdInView data={bannerData} />
  }

  return (
    <PaddedSection>
      <AdPlacement data={bannerData} width={sm ? 728 : 300} height={sm ? 90 : 50} />
    </PaddedSection>
  )
}
