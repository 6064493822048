import { Button, useButtonHandlers } from '../../controls'

export const chooseButtonVariant = ({ supportedRegion, hasSignedIn, hasSubscription }, config) =>
  (hasSubscription
    ? config.subscription
    : hasSignedIn
    ? config.member
    : supportedRegion
    ? config.guest
    : config.international) ?? config.default

export const ConfigurableButton = ({ config, ...props }) => {
  const { handlers, authStatus } = useButtonHandlers()
  const { action, text } = chooseButtonVariant(authStatus, config)
  const onPress = () => handlers[action](props)
  return <Button {...props} title={text} onPress={onPress} />
}
