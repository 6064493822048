// From expo-linear-gradient
import { forwardRef, useEffect, useState } from 'react'
import { View } from 'react-native'
import normalizeColor from 'react-native-web/src/modules/normalizeColor'

export default forwardRef(function NativeLinearGradient(
  { colors, locations, start, end, ...props },
  ref,
) {
  const [layout, setLayout] = useState(null)
  const [gradientColors, setGradientColors] = useState([])
  const [pseudoAngle, setPseudoAngle] = useState(0)

  const { width = 1, height = 1 } = layout ?? {}
  useEffect(() => {
    const getControlPoints = () => {
      const startPoint = !start ? undefined : Array.isArray(start) ? start : [start.x, start.y]
      const endPoint = !end ? undefined : Array.isArray(end) ? end : [end.x, end.y]

      let correctedStartPoint = [0, 0]
      if (Array.isArray(startPoint)) {
        correctedStartPoint = [
          startPoint[0] != null ? startPoint[0] : 0.0,
          startPoint[1] != null ? startPoint[1] : 0.0,
        ]
      }
      let correctedEndPoint = [0.0, 1.0]
      if (Array.isArray(endPoint)) {
        correctedEndPoint = [
          endPoint[0] != null ? endPoint[0] : 0.0,
          endPoint[1] != null ? endPoint[1] : 1.0,
        ]
      }
      return [correctedStartPoint, correctedEndPoint]
    }

    const [startCtrlPoint, endCtrlPoint] = getControlPoints()
    startCtrlPoint[0] *= width
    endCtrlPoint[0] *= width
    startCtrlPoint[1] *= height
    endCtrlPoint[1] *= height
    const py = endCtrlPoint[1] - startCtrlPoint[1]
    const px = endCtrlPoint[0] - startCtrlPoint[0]

    setPseudoAngle(90 + (Math.atan2(py, px) * 180) / Math.PI)
  }, [width, height, start, end])

  useEffect(() => {
    const nextGradientColors = colors.map((color, index) => {
      let output = normalizeColor(color) // A hex color
      if (locations && locations[index]) {
        const location = Math.max(0, Math.min(1, locations[index]))
        // Convert 0...1 to 0...100
        const percentage = location * 100
        output += ` ${percentage}%`
      }
      return output
    })

    setGradientColors(nextGradientColors)
  }, [colors, locations])

  const colorStyle = gradientColors.join(',')
  const backgroundImage = `linear-gradient(${pseudoAngle}deg, ${colorStyle})`
  return (
    <View
      {...props}
      ref={ref}
      style={[props.style, { backgroundImage }]}
      onLayout={(event) => {
        const { x, y, width, height } = event.nativeEvent.layout
        const oldLayout = layout ?? { x: 0, y: 0, width: 1, height: 1 }
        // don't set new layout state unless the layout has actually changed
        if (
          x !== oldLayout.x ||
          y !== oldLayout.y ||
          width !== oldLayout.width ||
          height !== oldLayout.height
        ) {
          setLayout({ x, y, width, height })
        }

        if (props.onLayout) {
          props.onLayout(event)
        }
      }}
    />
  )
})
