import { canUseDOM } from '../../../utils/canUseDOM.js'

export const makeAdPlacementQueryData = (commonConfig, placementConfig, extendedData) => ({
  ...commonConfig,
  ...placementConfig,
  data: {
    ...commonConfig?.data,
    ...placementConfig?.data,
    ...extendedData,
    requestUrl: canUseDOM ? window.location.href : 'NativeApp',
    referrerUrl: canUseDOM ? document.referrer : undefined,
    timeZone: -1 * new Date().getTimezoneOffset(),
    timeZoneHours: (-1 * new Date().getTimezoneOffset()) / 60,
  },
})
