import { View } from 'react-native'
import styled from 'styled-components'
import {
  ConfigurableButton,
  Content as ContentBase,
  Description,
  FullScreenBgSection,
  Heading,
} from '../../../elements/sections'
import { media } from '../../../style/media'
import { Callout } from './Callout'

const Content = styled(ContentBase)`
  align-items: flex-end;
`

const HeroInfo = styled(View)`
  margin: 0 auto;
  max-width: 100%;
  width: 740px;
  padding: 0 ${(props) => props.theme.spacing.small}px;
  ${media.sm`
    padding: 0;
  `};
`

const Callouts = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`

const responsive = {
  xs: { gradient: { x: 0, y: 0.2, strength: 10 } },
}

export const AboutHeroSection = ({ headerOffset, banner, heading, description, button, items }) => {
  const { landscape: bgImgIdLandscape, portrait: bgImgIdPortrait } = banner ?? {}
  return (
    <FullScreenBgSection
      bgImgIdLandscape={bgImgIdLandscape}
      bgImgIdPortrait={bgImgIdPortrait}
      responsive={responsive}
      gradientDirection={-1}
      headerOffset={headerOffset}
      justify="flex-end"
    >
      <Content>
        <HeroInfo>
          <Heading textAlign="center" margin="xsmall">
            {heading}
          </Heading>
          <Description textAlign="center">{description}</Description>
          <Callouts>
            {items.map((item) => (
              <Callout key={item.heading} {...item} />
            ))}
          </Callouts>
          {button && (
            <ConfigurableButton config={button} variant="primary" alignSelf="flex-start" />
          )}
        </HeroInfo>
      </Content>
    </FullScreenBgSection>
  )
}
