import { useCallback } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import { useLayout } from '../../../hooks'
import { BackgroundImage } from '../../images/BackgroundImage/BackgroundImage'
import { useFullScreenSectionDims } from './useFullScreenSectionDims'

export const HorizontalScrollStop = styled(View)`
  overflow: hidden;
  width: 100%;
`

const BgImgJustifyCenter = styled(BackgroundImage)`
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`

export const FullScreenBgSection = ({
  bgImgIdLandscape,
  bgImgIdPortrait,
  bgImgSource,
  backgroundPosition,
  gradientDirection = 1,
  headerOffset,
  mdPreferLandscape,
  responsive,
  justify = 'center',
  align = 'stretch',
  onLayout,
  contentStyle,
  style,
  children,
  absoluteChildren,
  parentMeasuredHeight,
}) => {
  const { onLayout: onOwnLayout, height: measuredHeight = 0 } = useLayout()
  const { width, height, gradient, imgSize, landscape } = useFullScreenSectionDims({
    measuredHeight: parentMeasuredHeight ?? measuredHeight,
    gradientDirection,
    mdPreferLandscape,
    headerOffset,
    responsive,
  })

  const publicId = landscape ? bgImgIdLandscape : bgImgIdPortrait

  const onChildLayout = useCallback(
    (size) => {
      onOwnLayout(size)
      onLayout?.(size)
    },
    [onOwnLayout, onLayout],
  )

  return (
    <HorizontalScrollStop style={style}>
      <BgImgJustifyCenter
        bgImgSource={bgImgSource}
        bgImgId={publicId}
        width={width}
        height={height}
        backgroundPosition={backgroundPosition}
        gradient={!gradientDirection ? null : gradient}
        transforms={imgSize}
        justify={justify}
        align={align}
      >
        <View onLayout={onChildLayout} style={contentStyle}>
          {children}
        </View>
        {absoluteChildren}
      </BgImgJustifyCenter>
    </HorizontalScrollStop>
  )
}
