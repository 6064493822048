import {
  ContentInfoBottom,
  ContentInfoLeft,
  FullScreenBgSection,
  VideoContent,
} from '../../../elements/sections'
import { useMediaIs } from '../../../style/media'

const gradient = { x: 0, y: 0.99, strength: 30 }
const responsive = {
  lg: { gradient },
  md: { gradient, minHeight: 640, maxHeight: 640 },
  sm: { gradient, minHeight: 640, maxHeight: 640 },
  xs: { gradient, maxHeight: 450 },
}

export const AboutVideoSection = ({ headerOffset, banner, video, ...section }) => {
  const lg = useMediaIs('lg')
  const Content = lg ? ContentInfoLeft : ContentInfoBottom
  const { landscape: bgImgIdLandscape, portrait: bgImgIdPortrait } = banner ?? {}
  return (
    <FullScreenBgSection
      bgImgIdLandscape={bgImgIdLandscape}
      bgImgIdPortrait={bgImgIdPortrait}
      responsive={responsive}
      gradientDirection={-1}
      headerOffset={headerOffset}
    >
      <Content {...section} headMargin="small">
        <VideoContent video={video} aspectRatio={1.75} maxHeight={555} />
      </Content>
    </FullScreenBgSection>
  )
}
