import { useEffect, useState } from 'react'
import { useAdsConfigQuery } from '@mwxltd/flix-api-client'
import { fetchBannerAdDecision } from './helpers/fetchBannerAdDecision.js'
import { makeAdPlacementQueryData } from './helpers/getAdPlacementQueryData.js'

export const useBannerAdDecision = (zone, placement, extendedData = {}) => {
  const { data: adsConfig, loading: loadingConfig } = useAdsConfigQuery()
  const [bannerData, setBannerData] = useState(null)
  const [loading, setLoading] = useState(true)

  const placementConfig = adsConfig?.zones?.[zone]?.placements?.[placement]

  useEffect(() => {
    if (placementConfig) {
      const adConfig = makeAdPlacementQueryData(adsConfig?.common, placementConfig, extendedData)
      fetchBannerAdDecision(adConfig).then((adData) => {
        setBannerData(adData)
        setLoading(false)
      })
    }
  }, [adsConfig?.common, placementConfig, ...Object.values(extendedData)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!adsConfig) {
    return { bannerData: { error: 'error', message: 'no ads' }, loading: loadingConfig }
  }

  return { bannerData, loading }
}
