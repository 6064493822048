import { useCallback, useRef } from 'react'
import { FlatList, Platform } from 'react-native'
import { CMSSection } from './CMSSection'

const isWeb = Platform.OS === 'web'

// todo The region should not be necessary once this is fully fed by the CMS

export const CMSSectionList = ({ sections, region }) => {
  const sectionList = useRef(null)

  const height = useRef(0)
  const onLayout = useCallback((e) => (height.current = e.nativeEvent.layout.height), [])

  const scrollToIndex = (index) => {
    isWeb
      ? window.scrollTo({ top: height.current * 0.95, behavior: 'smooth' }) // Only works for index === 1
      : sectionList.current.scrollToIndex({ index, animated: true })
  }

  return (
    <FlatList
      data={sections}
      ref={sectionList}
      extraData={region}
      keyExtractor={(item, index) => index.toString()}
      renderItem={({ item, index }) => (
        <CMSSection
          section={item}
          index={index}
          region={region}
          onLayout={index === 0 ? onLayout : undefined}
          onScrollToIndex={scrollToIndex}
        />
      )}
    />
  )
}
