import { ContentLimitedWidth } from '../../../elements/layout'
import { ExpandingTextList } from '../../../elements/lists/ExpandingTextList/ExpandingTextList'
import { PaddedSection } from '../../../elements/sections'
import { VeryBoldHeading } from '../../landing-pages'

export const ExpandingTextListSection = ({ heading, items }) => (
  <PaddedSection>
    <ContentLimitedWidth alignItems="center">
      {!!heading && (
        <VeryBoldHeading mb="large" textAlign="center">
          {heading}
        </VeryBoldHeading>
      )}
      <ExpandingTextList list={items} />
    </ContentLimitedWidth>
  </PaddedSection>
)
