"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useFlixApiContext } from '../FlixApiContext';
import { useFeatureFlag } from '../user/AuthStatus/useFeatureFlag';
var BANNER_ADS_CONFIG = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "variableDefinitions": [],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "adsBannersConfig"
        },
        "arguments": [],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "AdsConfig",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "info/ads/banners",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "common"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "zones"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listAdTemplates"
            },
            "arguments": [],
            "directives": []
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 158,
    "source": {
      "body": "\n  query {\n    adsBannersConfig @rest(type: \"AdsConfig\", endpoint: \"v2\", path: \"info/ads/banners\") {\n      common\n      zones\n      listAdTemplates\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var fetchAdsConfigQuery = function fetchAdsConfigQuery(client) {
  return client.query({
    query: BANNER_ADS_CONFIG
  });
};
export var useAdsConfigQuery = function useAdsConfigQuery(options) {
  var _result$data;

  var _useFeatureFlag = useFeatureFlag(),
      getFlagValue = _useFeatureFlag.getFlagValue;

  var adsDisabled = !getFlagValue('bannerAdsEnabled');
  var result = useQuery(BANNER_ADS_CONFIG, _objectSpread(_objectSpread({}, options), {}, {
    skip: adsDisabled
  }));
  return _objectSpread(_objectSpread({}, result), {}, {
    data: (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.adsBannersConfig,
    loading: adsDisabled ? false : result.loading
  });
};
export var useAdsConfigFetch = function useAdsConfigFetch() {
  var _useFlixApiContext = useFlixApiContext(),
      client = _useFlixApiContext.client;

  return useCallback(function () {
    return client.query({
      query: BANNER_ADS_CONFIG
    }).then(function (result) {
      return result.data.adsBannersConfig;
    });
  }, [client]);
};