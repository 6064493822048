import { View } from 'react-native'
import styled from 'styled-components'
import { ResponsiveImage } from '../../../elements/images/ResponsiveImage/ResponsiveImage'
import { Description } from '../../../elements/sections'
import { H3 } from '../../../elements/typography'
import { media } from '../../../style/media'

const CalloutContainer = styled(View)`
  flex: 0 0 31%;
`

const CalloutImage = styled(ResponsiveImage).attrs({
  aspectRatio: 1,
  maxHeight: 120,
})`
  margin-bottom: ${(props) => props.theme.spacing.smedium}px;
`

export const CalloutHeading = styled(H3)`
  margin-bottom: ${(props) => props.theme.spacing.xsmall}px;
  font-family: ${(props) => props.theme.fontFamilies.base};
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  line-height: ${(props) => props.theme.fontSizes.larger * 1.2}px;
  text-align: center;
`

export const CalloutDescription = styled(Description)`
  font-size: ${(props) => props.theme.fontSizes.small}px;
  line-height: ${(props) => props.theme.fontSizes.small * 1.3}px;
  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
    line-height: ${(props) => props.theme.fontSizes.larger * 1.2}px;
  `};
`

export const Callout = ({ image, heading, description }) => (
  <CalloutContainer>
    <CalloutImage publicId={image} />
    <CalloutHeading>{heading}</CalloutHeading>
    <CalloutDescription textAlign="center">{description}</CalloutDescription>
  </CalloutContainer>
)
