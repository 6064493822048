import styled from 'styled-components'
import { Text } from '../../../elements/typography'
import { media } from '../../../style/media'

export const VeryBoldHeading = styled(Text).attrs({
  accessibilityRole: 'header',
  'aria-level': 2,
})`
  font-family: ${(props) => props.theme.fontFamilies.marketingHeadlineBold};
  font-weight: 800;
  text-align: ${(props) => props.textAlign || 'center'};
  font-size: ${(props) =>
    props.fontSize ? props.theme.fontSizes[props.fontSize] : props.theme.fontSizes.xlarge}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'small']}px;

  ${media.sm`
    font-size: ${(props) =>
      props.fontSize ? props.theme.fontSizes[props.fontSize] : props.theme.fontSizes.xxlarge}px;
  `};
`
