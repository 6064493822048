import styled from 'styled-components'
import { Text } from '../../../elements/typography'
import { media } from '../../../style/media'

export const LandingPageSmallText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.small}px;
  line-height: ${(props) => props.theme.fontSizes.larger}px;
  margin-bottom: ${(props) => props.theme.spacing.xsmall}px;

  ${media.md`
    font-size: ${(props) => props.theme.fontSizes.larger}px;
  `}
`
