import { FormattedMessage } from 'react-intl'
import { Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../../../elements/images/Icon'
import { media } from '../../../style/media'
import { cursorPointer } from '../../../style/webStyles'

const ButtonBlock = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: ${(props) => props.minWidth ?? 260}px;
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  background-color: ${(props) => props.theme.colors[props.variant || 'primary']};
  padding: ${(props) => `${props.theme.spacing.medium}px ${props.theme.spacing.xlarge}px`};
  margin-right: ${(props) => props.theme.spacing[props.mr || 'none']}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'smedium']}px;
  border-radius: ${(props) => Math.round(props.theme.components.button.borderRadius * 2.5)}px;
  ${cursorPointer};
`

const Label = styled(Text)`
  color: ${(props) => props.theme.colors[props.textColor || 'white']};
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  line-height: ${(props) => props.theme.fontSizes.larger}px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fontFamilies.base};
  text-transform: uppercase;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xlarge}px;
    line-height: ${(props) => props.theme.fontSizes.xlarge}px;
  `}
`

const ButtonIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.spacing.xs}px;
`

export const LargeButton = ({
  icon,
  title,
  intlMsg,
  values,
  link,
  titleColor,
  iconColor,
  ...props
}) => {
  return (
    <ButtonBlock {...props}>
      {icon && <ButtonIcon scale={0.7} name={icon} color={iconColor} />}

      <Label numberOfLines={1} link={link} textColor={titleColor}>
        {title || <FormattedMessage {...intlMsg} values={values} />}
      </Label>
    </ButtonBlock>
  )
}

LargeButton.propTypes = {
  /** Minimum button width, useful to ensure a set of buttons have the same size */
  minWidth: PropTypes.number,
  /** Button displays as a link rather than a button */
  link: PropTypes.bool,
  /** Optional SVG icon name that will be displayed on the button */
  icon: PropTypes.string,
  /** Button text */
  title: PropTypes.string,
  /** Press event handler */
  onPress: PropTypes.func,
  /** Set the flex box `align-self` style, use for positioned buttons rather than full width */
  alignSelf: PropTypes.string,
}
