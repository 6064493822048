import { useCallback, useState } from 'react'

const emptyLayout = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

export function useLayout(defaultLayout = emptyLayout) {
  const [layout, setLayout] = useState(defaultLayout)
  const onLayout = useCallback((e) => setLayout(e.nativeEvent.layout), [])

  return {
    onLayout,
    ...layout,
  }
}
