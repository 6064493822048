import { StyleSheet } from 'react-native'
import { css } from 'styled-components'

export const commonStyles = StyleSheet.create({
  flex1: { flex: 1 },
})

export const absoluteFill = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const bold = css`
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
  font-weight: ${(props) => props.theme.fontWeights.bold};
`
