import { View } from 'react-native'
import styled from 'styled-components'
import {
  ConfigurableButton,
  Content as ContentBase,
  Description,
  FullScreenBgSection,
  Heading,
} from '../../../elements/sections'
import { useDimensions } from '../../../hooks'
import { media, useMediaIs } from '../../../style/media'

const Content = styled(ContentBase)`
  align-items: flex-end;
`

const AboutInfo = styled(View)`
  margin: 0 ${(props) => props.theme.spacing.medium}px;
  flex-shrink: 0;
  width: 77%;
  ${media.sm`
    margin-left: ${(props) => (props.alignRight ? 'auto' : `${props.theme.spacing.medium}px`)};
    margin-right: ${(props) => props.theme.spacing.medium}px;
    width: 65%;
  `};
  ${media.md`
    margin-left: ${(props) => (props.alignRight ? 'auto' : `${props.theme.spacing.xlarge}px`)};
    margin-right: ${(props) => props.theme.spacing.large}px;
    width: 50%;
  `};
  ${media.lg`
    width: 570px;
  `};
`

export const AboutSection = ({ align, banner, heading, description, button }) => {
  const sm = useMediaIs('sm')
  const { landscape } = useDimensions()
  const alignRight = landscape && sm && align === 'right'
  const { landscape: bgImgIdLandscape, portrait: bgImgIdPortrait } = banner ?? {}
  const responsive = {
    lg: {},
    md: landscape ? {} : { gradient: { x: 0, y: 0.25, strength: 30 } },
    sm: landscape ? {} : { gradient: { x: 0, y: 0.28, strength: 30 } },
    xs: { gradient: { x: 0, y: 0.6, strength: 30 } },
    xxs: { gradient: { x: 0, y: 0.85, strength: 25 } },
  }
  return (
    <FullScreenBgSection
      bgImgIdLandscape={bgImgIdLandscape}
      bgImgIdPortrait={bgImgIdPortrait}
      responsive={responsive}
      gradientDirection={landscape && sm && !alignRight ? 1 : -1}
      justify="flex-end"
    >
      <Content>
        <AboutInfo alignRight={alignRight}>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          {button && (
            <ConfigurableButton config={button} variant="primary" alignSelf="flex-start" />
          )}
        </AboutInfo>
      </Content>
    </FullScreenBgSection>
  )
}
