import { FormattedMessage } from 'react-intl'
import { Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useButtonHandlers } from '../../../elements/controls'
import { media } from '../../../style/media'
import { cursorPointer } from '../../../style/webStyles'
import messages from '../../nav/messages'

const ButtonBlock = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: ${(props) => `${props.theme.spacing.small}px ${props.theme.spacing.larger}px`};
  padding-right: ${(props) => props.theme.spacing.xs}px;
  border-radius: ${(props) => props.theme.components.button.borderRadius}px;
  ${cursorPointer};

  ${media.sm`
    padding: ${(props) => `${props.theme.spacing.medium}px ${props.theme.spacing.large}px`};
  `}

  ${media.md`
    background-color: ${(props) => props.theme.colors.white};
  `}
`

const Label = styled(Text)`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.large}px;
  line-height: ${(props) => props.theme.fontSizes.large}px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fontFamilies.base};
  text-transform: uppercase;

  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.large}px;
    line-height: ${(props) => props.theme.fontSizes.large}px;
  `}

  ${media.md`
    color: ${(props) => props.theme.colors.black};
  `}
`

export const LandingSignInButton = ({ msgValues, icon, link, ...props }) => {
  const { authStatus, handlers } = useButtonHandlers()
  const message = authStatus.hasSignedIn ? messages.signOut : messages.signIn
  const onPress = () => (authStatus.hasSignedIn ? handlers.signOut() : handlers.signIn())
  return (
    <ButtonBlock {...props} onPress={onPress} link={link}>
      <Label numberOfLines={1} link={link}>
        <FormattedMessage {...message} values={msgValues} />
      </Label>
    </ButtonBlock>
  )
}

LandingSignInButton.propTypes = {
  /** Provide a string title for the button text */
  title: PropTypes.string,
  /** Provide a string react-intl compatible message for the button text */
  intlMsg: PropTypes.object,
  /** Button displays as a link rather than a button */
  link: PropTypes.bool,
  /** Press event handler */
  onPress: PropTypes.func,
  /** Set the flex box `align-self` style, use for positioned buttons rather than full width */
  alignSelf: PropTypes.string,
}
