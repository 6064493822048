import { StyleSheet, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import Image from '../../../elements/images/Image/Image'
import { LinearGradient } from '../../../elements/images/LinearGradient/index.js'
import { ContentLimitedWidth, useDimensions } from '../../../elements/layout'
import { PaddedSection } from '../../../elements/sections'
import { media, useMediaIs, useMediaSelect } from '../../../style/media'
import { LandingPageParagraph, VeryBoldHeading } from '../../landing-pages'

const Row = styled(View)`
  width: 100%;
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  `};
`

const Col = styled(View)`
  flex: 1 1 50%;
  justify-content: center;
  align-items: center;

  ${media.lg`
    padding: 0 ${(props) => props.theme.spacing.medium}px;
    align-items: ${(props) => (props.rightCol ? 'flex-start' : 'flex-end')};
  `};
`

// Required to add space between image and text when text is on the right
const TextArea = styled(View)`
  ${media.lg`
    margin-left: ${(props) => (props.spacedLeft ? props.theme.spacing.xxxlarge : 0)}px;
  `};
`

/**
 * A two column section accepting text and an image respectively; reverse the order with the `reverse` prop.
 * A linear gradient can be applied to the entire background width of the section. Images are not intended
 * to cover the entire column width as a background image would.
 */
export const DescriptionImageTwoColSection = ({
  heading,
  description,
  color,
  image,
  imageMobile,
  reversed,
  linearGradient,
}) => {
  const theme = useTheme()
  const isMobile = !useMediaIs('md')
  const { width } = useDimensions()
  const relativeWidth = useMediaSelect({
    xs: 0.85,
    sm: 0.7,
    md: 0.8 * 0.5, // The column is only half the content width
  })
  const imageWidth = width * relativeWidth
  const img = isMobile && imageMobile ? imageMobile : image

  return (
    <PaddedSection style={isMobile ? styles.shadowProp : styles.borderProp}>
      {linearGradient && (
        <LinearGradient
          colors={linearGradient.colors}
          locations={linearGradient.location}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          style={StyleSheet.absoluteFill}
        />
      )}
      <ContentLimitedWidth>
        <Row reversed={reversed}>
          <Col rightCol={reversed}>
            <TextArea spacedLeft={reversed}>
              {!!heading && (
                <VeryBoldHeading
                  mb={isMobile ? 'medium' : 'small'}
                  textAlign={isMobile ? 'center' : 'left'}
                  color={color}
                >
                  {heading}
                </VeryBoldHeading>
              )}
              {!!description && (
                <LandingPageParagraph mb={isMobile ? 'none' : 'small'} color={color}>
                  {description}
                </LandingPageParagraph>
              )}
            </TextArea>
          </Col>
          <Col rightCol={!reversed}>
            {img && (
              <Image
                publicId={img.publicId}
                width={imageWidth}
                height={Math.round(imageWidth / img.aspectRatio)}
                mb={isMobile ? theme.spacing.large : undefined}
              />
            )}
          </Col>
        </Row>
      </ContentLimitedWidth>
    </PaddedSection>
  )
}

const styles = StyleSheet.create({
  borderProp: {
    borderTopColor: '#372E2E',
    borderTopWidth: 2,
  },
  shadowProp: {
    shadowColor: '#ddd',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 4,
  },
})
