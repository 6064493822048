import BackgroundImage from '../../../elements/images/BackgroundImage/BackgroundImage'
import Image from '../../../elements/images/Image/Image'
import {
  ContentLimitedWidth,
  useContentLimitedWidth,
  useDimensions,
  useLayout,
} from '../../../elements/layout'
import { PaddedSection } from '../../../elements/sections'
import { useImageAspectRatio } from '../../../hooks/useImageAspectRatio/useImageAspectRatio'
import { cloudinaryUrl } from '../../../services/cloudinary'
import { useMediaIs, useMediaSelect } from '../../../style/media'
import { CTAButton, LandingPageParagraph, VeryBoldHeading } from '../../landing-pages'

export const DescriptionImageOneColSection = ({
  heading,
  description,
  color,
  image,
  imageMobile,
  button,
  bgImgIdLandscape,
  bgImgIdPortrait,
  defaultHeight = 0,
  gradient,
}) => {
  const isMobile = !useMediaIs('md')
  const { landscape, width } = useDimensions()
  const contentLimitedWidth = useContentLimitedWidth()
  const { onLayout, height: contentHeight } = useLayout({
    x: 0,
    y: 0,
    width: 0,
    height: defaultHeight,
  })

  const bgImageId = landscape ? bgImgIdLandscape : bgImgIdPortrait ?? bgImgIdLandscape
  const imageBgAspectRatio = useImageAspectRatio(bgImageId && cloudinaryUrl(bgImageId))

  const img = isMobile && imageMobile ? imageMobile : image

  // Constrained image sizes, so they don't go up to the screen edge
  const relativeImageWidth = useMediaSelect({
    xs: 0.8,
    sm: 0.6,
    md: 0.8,
  })
  const imageWidth = contentLimitedWidth * relativeImageWidth

  return (
    <BackgroundImage
      bgImgId={bgImageId}
      width={width}
      height={Math.max(
        contentHeight,
        imageBgAspectRatio ? Math.round(width / imageBgAspectRatio) : 0,
      )}
      crop="crop"
      gradient={gradient}
    >
      <PaddedSection onLayout={onLayout}>
        <ContentLimitedWidth justifyContent="center" alignItems="center">
          {!!heading && (
            <VeryBoldHeading mb="large" textAlign="center" color={color}>
              {heading}
            </VeryBoldHeading>
          )}
          {!!description && (
            <LandingPageParagraph mb="large" textAlign="center" color={color}>
              {description}
            </LandingPageParagraph>
          )}
          {button && <CTAButton alignSelf="center" config={button} />}
          {img && (
            <Image
              publicId={img.publicId}
              width={imageWidth}
              height={Math.round(imageWidth / img.aspectRatio)}
              crop="scale"
            />
          )}
        </ContentLimitedWidth>
      </PaddedSection>
    </BackgroundImage>
  )
}
