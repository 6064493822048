export const handleInViewChange = (bannerData, disabled) => (inView) => {
  if (!disabled) {
    if (inView && bannerData?.beacons?.impression) {
      fetch(bannerData.beacons.impression)
    }

    if (inView && bannerData?.beacons?.noImpression) {
      fetch(bannerData.beacons.noImpression)
    }
  }
}
