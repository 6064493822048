import { useRef } from 'react'
import { Platform } from 'react-native'
import { useTheme } from 'styled-components'
import { useDimensions } from '../../../hooks'
import { useMediaBreakpoints, useMediaSelect } from '../../../style/media'
import { canUseDOM } from '../../../utils/canUseDOM'

// Since iOS reports iPads as Mac to get full size pages, the SSR returns an lg
// layout to iPad Pro in portrait. When height changes are locked this does not
// update properly on initial load. This is a hack to allow initial updates to
// complete before then locking to prevent issues when scrolling or zooming.
let lockSizeThreshold = false

if (!process.env.SERVER) {
  setTimeout(() => {
    lockSizeThreshold = true
  }, 700)
}

const isNative = Platform.OS !== 'web'
const getBrowserZoomScale =
  isNative || !canUseDOM ? () => 1 : () => document.body.clientWidth / window.innerWidth

const defaultOptions = {
  lg: {
    gradient: { x: 0.4, y: 0, strength: 30 },
    imgSize: { width: 1920, height: 950, crop: 'crop', gravity: 'north' },
  },
  md: {
    gradient: { x: 0.4, y: 0, strength: 30 },
    imgSize: { width: 1366, height: 1024, crop: 'fit' },
  },
  sm: {
    gradient: { x: 0.9, y: 0, strength: 30 },
    imgSize: { width: 1024, height: 768, crop: 'fit' },
  },
  xs: {
    gradient: { x: 0.9, y: 0, strength: 30 },
    imgSize: { width: 1080, height: 810, crop: 'fit' },
  },
}

const normalizeSize = ({ width: height, height: width, ...size }, invert) => ({
  ...size,
  width: invert ? height : width,
  height: invert ? width : height,
})

export const useFullScreenSectionDims = ({
  measuredHeight = 0,
  responsive,
  headerOffset = true,
  mdPreferLandscape = true,
  gradientDirection: gradientDirectionBase,
} = {}) => {
  const {
    components: { navbar },
  } = useTheme()

  const { md, lg } = useMediaBreakpoints()
  const navbarHeight = lg ? navbar.desktopHeight : navbar.mobileHeight

  const dimensions = useDimensions()
  const { rotationSafeWidth: windowWidth, safeAreaHeight: windowHeight } = dimensions

  // Mobile browsers shrink the address bar when scrolling down, which changes the
  // window height; to avoid slight layout changes on scroll small changes in the
  // window height are ignored. In addition iOS changes the window size when the
  // page is zoomed, so the size is only updated when the scale is close to 1.
  // Large screens (desktops) are excluded because this issue does not apply
  // and it causes issues with resizing client-side after SSR.
  const windowWidthRef = useRef(windowWidth)
  const windowHeightRef = useRef(windowHeight)
  const browserZoomScale = getBrowserZoomScale()

  if (
    lg ||
    !lockSizeThreshold ||
    (Math.abs(windowHeightRef.current - windowHeight) > 150 &&
      browserZoomScale >= 0.9 &&
      browserZoomScale <= 1.1)
  ) {
    windowWidthRef.current = windowWidth
    windowHeightRef.current = windowHeight
  }

  const options = useMediaSelect(defaultOptions)
  const responsiveOptions = useMediaSelect(responsive)

  let {
    gradient,
    gradientDirection,
    imgSize,
    minHeight = 0,
    maxHeight = 99999,
    orientation,
  } = Object.assign({ gradientDirection: gradientDirectionBase }, options, responsiveOptions)

  const windowHeightAvailable = windowHeightRef.current - (headerOffset ? navbarHeight : 0)

  let { landscape } = dimensions

  // When the iPad Pro, which is quite wide even in portrait mode, does not require a
  // full height section it works best to use the landscape rather than portrait image.
  if (mdPreferLandscape && md && maxHeight < windowHeightAvailable) {
    landscape = true
  }
  // A fixed background image orientation can be forced via the responsive options.
  if (orientation) {
    landscape = orientation === 'landscape'
  }

  if (minHeight > 0 && minHeight < 1) {
    minHeight = windowHeightAvailable * minHeight
  }
  if (maxHeight > 0 && maxHeight < 1) {
    maxHeight = windowHeightAvailable * maxHeight
  }

  // The full window height is used when no constraints have been specified, other
  // wise the min or max height will win out as appropriate.
  const requestedHeight = Math.min(Math.max(windowHeightAvailable, minHeight), maxHeight)

  return {
    landscape,
    // The measured height always wins out when it's greater than the requested
    // height so the content will fit in the section. (This only really works on web)
    height: Math.max(requestedHeight, measuredHeight),
    width: windowWidthRef.current,
    imgSize: normalizeSize(imgSize, landscape),
    gradient: {
      ...gradient,
      x: gradient.x * gradientDirection,
      y: gradient.y * gradientDirection,
    },
  }
}
