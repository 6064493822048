import { StyleSheet, View } from 'react-native'
import styled from 'styled-components'
import { LinearGradient } from '@flix/common/elements/images/LinearGradient'
import BackgroundImage from '../../../elements/images/BackgroundImage/BackgroundImage'
import { useDimensions } from '../../../elements/layout'
import { media, useMediaIs, useMediaSelect } from '../../../style/media'
import { LandingPageParagraph, VeryBoldHeading } from '../../landing-pages'

const ContentFullWidth = styled(View)`
  padding-bottom: ${(props) => (props.isMobile ? props.theme.spacing.large : 0)}px;
`

const Row = styled(View)`
  flex-direction: column-reverse;

  ${media.md`
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: ${(props) => (props.reversed ? 'row-reverse' : 'row')};
  `};
`

const Col = styled(View)`
  flex: 1 1 50%;
  justify-content: center;
`

const ContentDescription = styled(View)`
  padding: 0 ${(props) => props.theme.spacing.large}px;
  padding-top: ${(props) => props.theme.spacing.large}px;

  ${media.md`
    padding-top: 0;
    padding-left: ${(props) => props.theme.spacing.large}px;
`};
  ${media.lg`
    max-width: 70%;
`};
`
// This component is a full width section row with two columns accepting an image and text respectively.
// This component has linear gradient capabilities.
// One gradient can be adjusted to the entire background of the section.
// The other can be adjusted as an overlay gradient transition from the image to the text column.
export const DescriptionBgImageTwoColSection = ({
  heading,
  description,
  color,
  image,
  imageMobile,
  reversed,
  textPosition,
  linearGradient,
}) => {
  const isMobile = !useMediaIs('md')
  const { width } = useDimensions()
  const relativeWidth = useMediaSelect({
    xs: 1.2,
    sm: 1.2,
    md: 0.8 * 0.62,
  })

  const imageWidth = width * relativeWidth

  const img = isMobile && imageMobile ? imageMobile : image

  return (
    <ContentFullWidth
      isMobile={isMobile}
      style={isMobile ? styles.insetShadowProp : styles.shadowProp}
    >
      <LinearGradient
        colors={linearGradient.colors}
        locations={linearGradient.location}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={StyleSheet.absoluteFill}
      />
      <Row reversed={reversed}>
        <Col>
          <ContentDescription alignText={textPosition}>
            {!!heading && (
              <VeryBoldHeading textAlign={isMobile ? 'center' : 'left'} color={color}>
                {heading}
              </VeryBoldHeading>
            )}
            {!!description && (
              <LandingPageParagraph color={color}>{description}</LandingPageParagraph>
            )}
          </ContentDescription>
        </Col>
        <Col>
          {img && (
            <>
              <BackgroundImage
                bgImgId={img.publicId}
                width={imageWidth}
                height={Math.round(imageWidth / img.aspectRatio)}
                style={{ alignSelf: isMobile ? 'center' : img.position }}
              />
              {!isMobile && (
                <LinearGradient
                  colors={['rgba(0, 0, 0, 0)', 'rgba(18, 18, 18, 1)']}
                  locations={[0.7, 0]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  style={StyleSheet.absoluteFill}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </ContentFullWidth>
  )
}

const styles = StyleSheet.create({
  shadowProp: {
    shadowColor: '#ddd',
    shadowOffset: { width: -2, height: -2 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 4,
  },
  insetShadowProp: {
    shadowColor: '#ddd',
    shadowOffset: { width: 3, height: 10 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 4,
  },
})
